<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
            <div>
                <el-select size="small" v-model="search.cate_id" placeholder="请选择分类筛选" clearable @change="searchTag">
                    <el-option
                        v-for="item in cates"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <el-button size="small" type="primary" @click="add()">添加</el-button>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="id" label="id"></el-table-column>
			<el-table-column prop="tag_name" label="名称"></el-table-column>
            <el-table-column prop="cate_name" label="分类"></el-table-column>
            <el-table-column prop="created_at" label="添加时间"></el-table-column>
            <el-table-column label="操作">
				<template slot-scope="scope">
					<el-link type="primary" @click="edit(scope.row)" style="margin-right: 10px">修改</el-link>
                    <el-link type="primary" @click="deleteTag(scope.row.id)">删除</el-link>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
        <tagAdd ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :row="row" :cates="cates" @getList="getList" @close="close"/>
	</div>
</template>
<script>
	import tagAdd from './component/tagAdd.vue'
	import { allCate } from '@/api/base.js';
	import { tagList, delTag } from '@/api/classTag.js';
	export default {
		name: "TagLibrary",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				cates: [],
                search: {
                    cate_id: '',
                },
				// 列表
				total: 0,
				limit: 20,
				currentPage: 1,
				list: [],
                // 弹框
                typeFlag: 'add',
                dialogVisible: false,
                row: {},
			}
		},
		mounted() {
			this.getCate()
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		components: {
			tagAdd
		},
		methods: {
			// 添加
			add() {
				this.typeFlag = 'add'
				this.row = {}
				this.dialogVisible = true
            },
            // 修改
			edit(row) {
                this.typeFlag = 'edit'
				this.row = row
				this.dialogVisible = true
            },
            // 删除
            deleteTag(id){
				this.$confirm('确定删除吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delTag({ id }).then((res) => {
						if(res.code == 200) {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.currentPage = 1
							this.getList()
						}
					}).catch((err) => {
						console.log(err, 222)
					})
				}).catch(() => {});
                console.log(id)
			},
			// 获取分类
			getCate() {
				allCate({}).then((res) => {
					this.cates = res.data
				}).catch((err) => {
					console.log(err)
				})
			},
			// 获取列表
			getList() {
				this.loading = true
				tagList({
					limit: this.limit,
					page: this.currentPage,
					cate_id: this.search.cate_id ? this.search.cate_id : null
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false
				}).catch((err) => {
					this.loading = false
					console.log(err)
				})
			},
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			// 筛选
			searchTag() {
				this.currentPage = 1;
				this.getList();
			},
            // 关闭弹框
            close() {
                this.dialogVisible = false
            },
		}
	};
</script>
