<template>
	<div class="dialog">
		<el-dialog :title="typeFlag=='add'?'添加':'修改'" :visible.sync="dialogVisible" width="500px" :before-close="close">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="请选择分类" prop="cate_id">
					<el-select size="small" v-model="ruleForm.cate_id" placeholder="请选择">
                        <el-option
                            v-for="item in cates"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
				</el-form-item>
				<el-form-item label="标签" prop="name">
					<el-input size="small" v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close()">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	import { saveTag } from '@/api/classTag'
	export default {
		name: "TagAdd",
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			typeFlag: { // 判断是新建 编辑 详情
				type: String,
				default: 'add'
			},
			cates: {
				type: Array,
				default: ()=>{
					return []
				}
			},
			row: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		data() {
			return {
				// 弹框新建
				ruleForm: {
					cate_id: '',
					name: '',
				},
				rules: {
					cate_id: [
						{ required: true, message: '请选择分类', trigger: 'change' }
					],
					name: [
						{ required: true, message: '请输入标签', trigger: 'blur' }
					],
				}
			}
		},
		watch: {
			row: {
				handler(newVal) {
					this.ruleForm.cate_id = newVal.cate_id
					this.ruleForm.name = newVal.tag_name
				},
				immediate: true
			},
		},
		methods: {
			// 确定
            submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.save()
					} else {
						return false;
					}
				});
			},
			save(){
				let body = this.ruleForm
				this.typeFlag == 'edit' ? body.id = this.row.id : ''
				saveTag(body).then((res) => {
					if(res.code == 200){
						this.close()
						this.$emit('getList');
					}
				}).catch((err) => {
					console.log(err, 222)
				})
			},
            // 关闭
			close() {
				this.$emit('close');
			},
		}
	};
</script>
